
<template>
  <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
    <div>
      <b-card title="Profile informations">
        <b-row>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Nom"
              label-for="basicInput"
            >
              <b-form-input
               required
                id="basicInput"
                v-model="form.brand_name"
               
                placeholder="Brand name"
              />
            </b-form-group>

          </b-col>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Brand logo"
              label-for="Photo"
            >
            
            <b-media vertical-align="top" v-if="file!==null">
                  
                    <img
                      :src="file"
                      blank-color="#ccc"
                      width="50"
                      alt="placeholder"
                      
                    />
                 
                  
                  <b-card-text class="mb-0">
                
                    <b-button
                    size="sm"
                      
                      variant="outline-warning"
                      class="btn-icon"
                      @click="editPhoto()"
                    >
                      <feather-icon icon="EditIcon" />
                    </b-button>
                  </b-card-text>
                </b-media>
            <b-form-file v-else
            required
                v-model="file"
                :state="Boolean(file)"
                placeholder="Choose or drop file..."
                drop-placeholder="Drop here ..."
                @change="fileAdded($event)"
            />
        
            </b-form-group>
              
          </b-col>
     

        </b-row>
  
      </b-card>

      <b-button 
       @click="editProfile"
      >
      Save
      </b-button>
  
    </div>
  </b-overlay>
  </template>
  
  <script>
  import { BButton,BFormInput,BOverlay, BRow, BCol,BCardText,BFormGroup,BFormFile, BMedia,BCard, BFormCheckbox, BFormTextarea} from 'bootstrap-vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import vSelect from 'vue-select'
  import {APIRequest,Auth} from '@simb2s/senseye-sdk'
  
  export default {
    name: 'Profile',
    components: {
      BButton,
      BMedia,
      BFormInput,
      BCardText,
      BFormGroup,
      BRow,
      BCol,
      BFormFile,
      BOverlay,
      BCard,
      BFormCheckbox,
      BFormTextarea,
      vSelect
    },
    data() {
        return {
            edition:false,
            loading:true,
            currentBase64:null,
            file: null,
            form:{
              brand_name:"",
              brand_logo:null,
              stl_files:false
            },
        }
    },
   
    methods:{
      editPhoto() {
        this.file=null
      },
      fileAdded(event)
      {
        var reader = new FileReader()
        reader.readAsDataURL(event.target.files[0])
        reader.onload = () => {
            
            let b64=reader.result.split(';')
            let type=b64[0]
            if(type.includes('image'))
            {
                let base64=b64[1]
                //base64.replace('base64,','')
                this.currentBase64=reader.result
                this.file=this.currentBase64
                this.form.brand_logo=this.currentBase64
            }
            else
            {
                this.file=null
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "This is not an image!",
                    icon: 'AlertCircleIcon',
                    variant: 'warning',
                  },
                })
            }
            
        };
      },
      async editProfile()
      {
        this.loading=true
        let datas = {}
          datas.brand_logo = this.form.brand_logo
          datas.brand_name = this.form.brand_name
          //datas.stl_files = this.form.stl_files
          
          try{
               
              await Auth.update_account(this.$route.params.id, datas)
              this.loading=false
              this.$toast({
                  component: ToastificationContent,
                  props: {
                      title: 'Profile updated !',
                      icon: 'CheckIcon',
                      variant: 'success',
                  },
              })

              
            
              
          } catch (error) {
            
            this.loading=false
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'An error has occured.',
                    icon: 'AlertCircleIcon',
                    variant: 'warning',
                },
            })
            
          }


        
      },
   
    },
  
    
      
    async mounted(){

      let user=await Auth.get_user_with_id(this.$route.params.id)
      console.log('user')
      console.log(user)
      //this.form.brand_logo=user.brand_logo
      this.form.brand_name=user.brand_name
      //this.form.stl_files=user.stl_files
      this.file=user.brand_logo
     
      this.loading=false
    }
  
  }
  </script>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>